import Vue from 'vue';

import Router from 'vue-router';
import editBDR from '../views/editBDR';
import keyFact from '../views/KeyFacts';
import managementReporting from '../views/ManagementReporting'
import Fin_Operation from '../components/Table/Directory/Fin_Operation'
import fines from '../views/Fines.vue'
import main_page from '../views/MainPage.vue'
import administration from '../views/Administartion.vue'
import insurance_wagons from '../views/InsuranceWagons.vue'
// C:\Users\Asus\Desktop\VueTTRe\project\src\views\Fines.vue
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/main',
      component: main_page
    },
    {
      path: '/wagonpark',
      name: 'WagonPark',
      component: () => import('../views/WagonPark'),
    },
    {
      path: '/directory',
      name: 'Directrory',
      component: () => import('../views/Home'),
    },

    {
      path: '/lk',
      name: 'LK',
      component: () => import ('../views/lk'),
    },
    {
      path: '/report',
      name: 'Report',
      component: () => import ('../views/report'),
    },
    {
      path: '/edit-bdr/:id',
      component: editBDR
    },
    {
      path: '/fin_operation/:id',
      component: Fin_Operation
    },
    {
      path: '/personnel-service',
      component: () => import('../views/Staff'),
    },
    {
      path: '/key-facts',
      component: keyFact
    },
    {
      path: '/management-reporting',
      component: managementReporting
    },
    {
      path: '/fines',
      name: 'Fines',
      component: fines
    },
    {
      path: '/administration',
      name: 'Administration',
      component: administration
    },
    {
      path: '/staff/:userId',
      name: 'Staff',
      component: () => import('@/components/Table/Staff/WorkWithStaff/StaffComponent.vue'),
      redirect: (to) => ({ name: 'staffTab.myApplications', params: to.params }),
      props: (to) => ({
        staffTab: to.params.staffTab
      }),
      children: [
        {
          path: "lk",
          name: "staffTab.lk",  
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/MyProfileModule.vue')
        },
        {
          path: "work-with-staff",
          name: "staffTab.myApplications",
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/MyApplicationsModule.vue')
        },
        {
          path: "employees-of-the-company",
          name: "staffTab.employeesOfTheCompany",
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/StaffListModule.vue')
        },
        {
          path: "birthday-employees",
          name: "staffTab.birthdayEmployees",
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/BirthdayModule.vue')
        },
        {
          path: "for-new-employee",
          name: "staffTab.forNewEmployee",
          component: () => import('@/components/Table/Staff/WorkWithStaff/pages/NewEmployeePage.vue')
        },
        {
          path: "staff-news",
          name: "staffTab.staffNews",
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/NewsModule.vue')
        },
        {
          path: "reports",
          name: "staffTab.reports",
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/ReportsModule.vue')
        },
        {
          path: "new-application",
          name: "staffTab.newApplication",
          component: () => import('@/components/Table/Staff/WorkWithStaff/module/NewApplicationModule.vue')
        }
      ]
    
    },
    {
      path: '/insurance',
      name: 'Insurance',
      component: insurance_wagons,
    },
    {
      path: '*',
      component: main_page
    }
  ]
})
