<template>
  <div v-show="visibleElement" style="z-index: 100">
    <!-- <div>
      <button @click="cData()">Быстрая загрузка ПВ</button>
    </div> -->
    <!-- <Modal_Notification
      :modal_notifications="modal_notifications"
      @close="close_modal"
      :data="data"
    /> -->
    <div>
      <b-navbar toggleable="lg" class="mobile" type="light" style="background: #f8f9fa !important" ref="navbar">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item>
              <router-link to="/main" class="router-links">Главная</router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link to="/directory" class="router-links">Справочники</router-link>
            </b-nav-item>

            <b-nav-item>
              <router-link :to="{ name: 'WagonPark' }" class="router-links">Вагонный парк</router-link>
            </b-nav-item>
            <!-- 
        <b-nav-item>
          <router-link to="/lk" class="router-links"
            >Личный кабинет</router-link
          >
        </b-nav-item> -->

            <b-nav-item>
              <router-link to="/report" class="router-links">Отчеты</router-link>
            </b-nav-item>

            <b-nav-item>
              <router-link to="/key-facts" class="router-links">Ключевые факты</router-link>
            </b-nav-item>

            <b-nav-item>
              <router-link to="/management-reporting" class="router-links">Управ. отчетность</router-link>
            </b-nav-item>

            <b-nav-item>
              <router-link to="/fines" class="router-links">Штрафы</router-link>
            </b-nav-item>

            <b-nav-item>
              <router-link to="/personnel-service" class="router-links">Справочная информация</router-link>
            </b-nav-item>

            <b-nav-item>
              <router-link to="/insurance" class="router-links">Страхование</router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link :to="{ name: 'Staff', params: { userId: uid } }" class="router-links">Работа с персоналом</router-link>
            </b-nav-item>
            <li class="nav-item" style="padding-top: 1px">
              <a class="nav-link" @click="logout" style="color: black; text-decoration: none; font-size: 15px">Выход</a>
            </li>

            <li class="nav-item" style="padding-top: 1px; margin-left: auto" @click="Notif()">
              <i class="block nav-link">
                <img src="@/assets/bell.png" alt="" />
                <div class="circle" v-if="notifications_queue">
                  <span class="circle_notif">{{ count }}</span>
                </div>
              </i>
            </li>
            <li class="nav-item" style="padding-top: 1px; padding-left: 35px">
              <b-form-checkbox class="nav-link" switch @change="newYearStart = !newYearStart"></b-form-checkbox>
            </li>
            <li class="nav-item last">
              <i class="block nav-link">
                <img src="@/assets/logo2.png" height="10" alt="Логотип компании" />
              </i>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div class="b-page_newyear" v-show="newYearStart">
        <div class="b-page__content" :style="contentStyle">
          <i class="b-head-decor">
            <i class="b-head-decor__inner b-head-decor__inner_n1">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="0">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="7">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="8">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
            <i class="b-head-decor__inner b-head-decor__inner_n2">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="9">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="10">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="11">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="12">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="13">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="14">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="15">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="16">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="17">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
            <i class="b-head-decor__inner b-head-decor__inner_n3">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="18">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="19">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="20">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="21">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="22">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="23">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="24">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="25">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="26">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
            <i class="b-head-decor__inner b-head-decor__inner_n4">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="27">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="28">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="29">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="30">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="31">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="32">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="33">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="34">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="35">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
            <i class="b-head-decor__inner b-head-decor__inner_n5">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="0">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="7">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="8">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
            <i class="b-head-decor__inner b-head-decor__inner_n6">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="9">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="10">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="11">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="12">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="13">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="14">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="15">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="16">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="17">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
            <i class="b-head-decor__inner b-head-decor__inner_n7">
              <div class="b-ball b-ball_n1 b-ball_bounce" data-note="18">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n2 b-ball_bounce" data-note="19">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n3 b-ball_bounce" data-note="20">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n4 b-ball_bounce" data-note="21">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n5 b-ball_bounce" data-note="22">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n6 b-ball_bounce" data-note="23">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n7 b-ball_bounce" data-note="24">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n8 b-ball_bounce" data-note="25">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_n9 b-ball_bounce" data-note="26">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i1">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i2">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i3">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i4">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i5">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
              <div class="b-ball b-ball_i6">
                <div class="b-ball__right"></div>
                <div class="b-ball__i"></div>
              </div>
            </i>
          </i>
        </div>
      </div>
    </div>

    <div class="phone_navbar" @click="$router.push('/main')" v-if="$route.path != '/main' && $route.path != '/'">
      <span>На главную</span>
      <!-- <ul>
        <li>Главная</li>
      </ul> -->
    </div>
  </div>
</template>
<script>
  import { actionTypes } from "@/store/modules/auth";
  import Modal_Notification from "@/components/ui/Modal_Notification.vue";
  import api from "@/api/report";
  import { mapState } from "vuex";
  import { param } from "jquery";

  export default {
    name: "UpNavbar",
    components: { Modal_Notification },
    data() {
      return {
        notifications_queue: true,
        count: 0,
        modal_notifications: false,
        data: [],
        uid: JSON.parse(localStorage.getItem("vuex")).auth.uid,
        newYearStart: false,
      };
    },
    mounted() {},
    computed: {

      visibleElement() {
        if (!window.location.href.includes("fin_operation")) {
          return true;
        } else {
          return false;
        }
      },
      contentStyle() {
        // Проверяем текущий маршрут
        if (this.$route.path === "/" || this.$route.path === "/main") {
          return { minHeight: "0px" };
        } else {
          return { minHeight: "80px" };
        }
      },
      notification() {
        if (this.count == 0) {
          return "@/assets/bell.png";
        }
        return "@/assets/bell-color.png";
      },
    },
    methods: {
      handleStorageChange() {
        // Обновляем состояние при изменении значения в localStorage
        this.newYearStart = JSON.parse(localStorage.getItem("newYearStart"));
      },
      async cData() {
        let arr = [
          'АО "УРАЛЬСКАЯ СТАЛЬ"',
          "Алоран",
          "Газпром Нефть, ПАО",
          "ДЕЛОВЫЕ ПРОГРАММЫ, ООО",
          "КТС, ООО",
          "МТК, ООО",
          "Мечел-Транс",
          "НАЦИОНАЛЬНАЯ ТРАНСПОРТНАЯ КОМПАНИЯ, АО",
          "НЕРУДНАЯ ЛОГИСТИЧЕСКАЯ КОМПАНИЯ, ООО",
          "НОВАЯ ГОРНАЯ УК, ООО",
          'ООО "Альфа-транс"',
          "ПЕРВООСНОВА, АО",
          "ППО",
          "РУССКИЙ УГОЛЬ, АО",
          "СДС-Уголь",
          "СПО, ООО",
          "ТАТНЕФТЬ-ТРАНС, ООО",
          "ТК РУТ Логистик",
          "ТЭК УЛЬТИМА, ООО",
          "ТрансКом ТОО",
          "УГПХ, ООО",
          "Уголь-Транс",
          "ЧЭМК, АО",
          "ЭЛСИ ЛОГИСТИКА СИБИРЬ, ООО",
        ];

        let promises = arr.map((item) => api.vigrData(item, "2024-03-31", "Полувагон"));
        Promise.all(promises)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getTasks() {
        const preid = JSON.parse(localStorage.getItem("vuex"));
        const id = preid.auth.uid;
        setInterval(() => {
          api
            .personalTasks(id)
            .then((response) => {
              this.data = response.data.data;
              // console.log(this.data)
              let a = [...this.data];
              let b = a.reduce((acc, item) => {
                if (item.status == "in_work") {
                  return [...acc, item];
                } else {
                  return acc;
                }
              }, []);
              this.count = b.length;
            })
            .catch((error) => {
              console.log(error);
            });
        }, 5000);
      },
      logout() {
        this.$store.dispatch(actionTypes.logout);
        window.location.href = "/";
      },
      Notif() {
        this.modal_notifications = true;
      },
      close_modal() {
        this.modal_notifications = false;
      },
    },
  };
</script>

<style scoped>
  .b-page__content {
    min-height: 80px;
  }

  .b-head-decor {
    display: none;
  }

  .b-page_newyear .b-head-decor {
    position: absolute;
    top: 50px;
    left: 0;
    display: block;
    height: 115px;
    width: 100%;
    overflow: hidden;
    z-index: 50 !important;
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-head-decor_newyear.png) repeat-x 0 0;
  }

  .b-page_newyear .b-head-decor__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 115px;
    display: block;
    width: 373px;
  }

  .b-page_newyear .b-head-decor::before {
    content: "";
    display: block;
    position: absolute;
    top: -115px;
    left: 0;
    z-index: 3;
    height: 115px;
    display: block;
    width: 100%;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.75);
  }

  .b-page_newyear .b-head-decor__inner_n2 {
    left: 373px;
  }

  .b-page_newyear .b-head-decor__inner_n3 {
    left: 746px;
  }

  .b-page_newyear .b-head-decor__inner_n4 {
    left: 1119px;
  }

  .b-page_newyear .b-head-decor__inner_n5 {
    left: 1492px;
  }

  .b-page_newyear .b-head-decor__inner_n6 {
    left: 1865px;
  }

  .b-page_newyear .b-head-decor__inner_n7 {
    left: 2238px;
  }

  .b-ball {
    position: absolute;
  }

  .b-ball_n1 {
    top: 0;
    left: 3px;
    width: 59px;
    height: 83px;
  }

  .b-ball_n2 {
    top: -19px;
    left: 51px;
    width: 55px;
    height: 70px;
  }

  .b-ball_n3 {
    top: 9px;
    left: 88px;
    width: 49px;
    height: 67px;
  }

  .b-ball_n4 {
    top: 0;
    left: 133px;
    width: 57px;
    height: 102px;
  }

  .b-ball_n5 {
    top: 0;
    left: 166px;
    width: 49px;
    height: 57px;
  }

  .b-ball_n6 {
    top: 6px;
    left: 200px;
    width: 54px;
    height: 70px;
  }

  .b-ball_n7 {
    top: 0;
    left: 240px;
    width: 56px;
    height: 67px;
  }

  .b-ball_n8 {
    top: 0;
    left: 283px;
    width: 54px;
    height: 53px;
  }

  .b-ball_n9 {
    top: 10px;
    left: 321px;
    width: 49px;
    height: 66px;
  }

  .b-ball_n1 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n1.png) no-repeat;
  }

  .b-ball_n2 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n2.png) no-repeat;
  }

  .b-ball_n3 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n3.png) no-repeat;
  }

  .b-ball_n4 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n4.png) no-repeat;
  }

  .b-ball_n5 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n5.png) no-repeat;
  }

  .b-ball_n6 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n6.png) no-repeat;
  }

  .b-ball_n7 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n7.png) no-repeat;
  }

  .b-ball_n8 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n8.png) no-repeat;
  }

  .b-ball_n9 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_n9.png) no-repeat;
  }

  .b-ball_i1 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_i1.png) no-repeat;
  }

  .b-ball_i2 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_i2.png) no-repeat;
  }

  .b-ball_i3 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_i3.png) no-repeat;
  }

  .b-ball_i4 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_i4.png) no-repeat;
  }

  .b-ball_i5 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_i5.png) no-repeat;
  }

  .b-ball_i6 .b-ball__i {
    background: url(https://raw.githubusercontent.com/bybelov/new-year-garland/master/balls/b-ball_i6.png) no-repeat;
  }

  .b-ball_i1 {
    top: 0;
    left: 0;
    width: 25px;
    height: 71px;
  }

  .b-ball_i2 {
    top: 0;
    left: 25px;
    width: 61px;
    height: 27px;
  }

  .b-ball_i3 {
    top: 0;
    left: 176px;
    width: 29px;
    height: 31px;
  }

  .b-ball_i4 {
    top: 0;
    left: 205px;
    width: 50px;
    height: 51px;
  }

  .b-ball_i5 {
    top: 0;
    left: 289px;
    width: 78px;
    height: 28px;
  }

  .b-ball_i6 {
    top: 0;
    left: 367px;
    width: 6px;
    height: 69px;
  }

  .b-ball__i {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }

  .b-ball_bounce .b-ball__right {
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    bottom: 0;
    z-index: 9;
  }

  .b-ball_bounce:hover .b-ball__right {
    display: none;
  }

  .b-ball_bounce .b-ball__right:hover {
    left: 0;
    display: block !important;
  }

  .b-ball_bounce.bounce > .b-ball__i {
    -webkit-transform: rotate(-9deg);
    -moz-transform: rotate(-9deg);
    -o-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }

  .b-ball_bounce .b-ball__right.bounce + .b-ball__i {
    -webkit-transform: rotate(9deg);
    -moz-transform: rotate(9deg);
    -o-transform: rotate(9deg);
    transform: rotate(9deg);
  }

  .b-ball_bounce.bounce1 > .b-ball__i {
    -webkit-transform: rotate(6deg);
    -moz-transform: rotate(6deg);
    -o-transform: rotate(6deg);
    transform: rotate(6deg);
  }

  .b-ball_bounce .b-ball__right.bounce1 + .b-ball__i {
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }

  .b-ball_bounce.bounce2 > .b-ball__i {
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  .b-ball_bounce .b-ball__right.bounce2 + .b-ball__i {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  .b-ball_bounce.bounce3 > .b-ball__i {
    -webkit-transform: rotate(1.5deg);
    -moz-transform: rotate(1.5deg);
    -o-transform: rotate(1.5deg);
    transform: rotate(1.5deg);
  }

  .b-ball_bounce .b-ball__right.bounce3 + .b-ball__i {
    -webkit-transform: rotate(-1.5deg);
    -moz-transform: rotate(-1.5deg);
    -o-transform: rotate(-1.5deg);
    transform: rotate(-1.5deg);
  }

  a.router-links {
    font-size: 0.9rem;
    white-space: nowrap;
  }
  .block {
    position: relative;
    cursor: pointer;
  }

  .circle {
    position: absolute;
    top: 0;
    right: 0;
    /* margin: 10% 0 150% 0; */
    width: 17px;
    height: 17px;
    border-radius: 100%;

    display: flex;
    justify-content: space-between;
  }
  .phone_navbar {
    display: none;
  }
  .circle_notif {
    color: orange;
    font-weight: bold;
    font-size: 14px;
    margin-left: 30%;
    width: 100%;
    /* position: relative;
  left: 0%;
  bottom: 50%;
  transform: translate(-0%, 0); */
  }
  .navbar {
    height: 6vh;
    /* margin-bottom: 2vh; */
  }
  .router-links {
    text-decoration: none;
    font-size: 15px;
    line-height: 24px;
    color: #1a1a1a;
    font-family: "Inter var", sans-serif;
    font-weight: 500;
  }

  .disabled {
    pointer-events: none;
    /* background: #E4E4E4; */
    color: #e4e4e4;
    background: #f8f9fa !important;
  }
  a:hover {
    color: rgb(73, 73, 73) !important;
  }
  a.router-link-active,
  li.router-link-active {
    border-bottom: 2px solid #ec2332;
  }
  a.router-link {
    text-decoration: none;
    border-bottom: none;
  }
  .last {
    padding-top: 1px;
    margin-left: auto !important;
    float: right;
    position: absolute;
    right: 0;
  }
  @media screen and (max-width: 1000px) {
    .navbar-nav {
      background: #f8f9fa !important;
      padding-left: 2% !important;
      position: absolute;
      z-index: 10000000000000000 !important;
      width: 95vw;
    }
    .mobile {
      display: none;
    }
    .phone_navbar {
      display: block;
      position: fixed;
      bottom: 10px;
      background: rgb(86, 162, 185);
      width: 50%;
      left: 25%;
      border-radius: 20px;
      height: 5vh;
      z-index: 100 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .phone_navbar span {
      color: white;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1550px) {
    a.router-links {
      font-size: 0.87em;
    }
  }
</style>
